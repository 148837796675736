<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="destinations"
      :importing="importing"
      @mapValues="mapValues"
      @import="importDestinations"
      @geocode="geocodeDestinations"
      showgeocodeButton
      ref="importTableRef"
    ></import-table>

    <div class="text-center">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Geocode {{ rows.length }} {{ pluralize('Place', rows.length) }}
          </v-card-title>

          <v-card-text class="py-2" v-if="!showGeocodeError">
            <p v-if="isGeocoding">Geocoding Location {{ geoCodingValue }}/{{ rows.length }}</p>
            <p v-if="!isGeocoding && geoCodingValue == rows.length">
              Geocoding complete for {{ geoCodingValue - failedGeocodes }} {{ pluralize('place', rows.length) }}
            </p>
            <v-progress-linear color="light-green darken-4" height="10" :value="progressValue"></v-progress-linear>
          </v-card-text>

          <v-card-text class="py-2" v-else> Geocoding could not be completed. </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="(!isGeocoding && geoCodingValue == rows.length) || showGeocodeError"
              color="primary"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { mapActions } from 'vuex';
import { pluralize } from '@/util';
import { GEOCODE } from '@/store/modules/Geo/actions';

export default {
  name: 'DestinationTable',
  inject: ['eventHub'],
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fields() {
      return [
        {
          label: 'Name*',
          value: 'name',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Category',
          value: 'category',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Address*',
          value: 'address',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Address 2',
          value: 'address2',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'City*',
          value: 'city',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'State*',
          value: 'state',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Zip*',
          value: 'zip',
          type: 'number',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Coordinates*',
          value: 'coordinates',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Phone',
          value: 'phone',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Email',
          value: 'email',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Cert Liab Exp Dt',
          value: 'liabilityExp',
          type: 'date',
          validations: [],
          mappedTo: null,
        },
      ];
    },
    progressValue() {
      return ((this.geoCodingValue - this.failedGeocodes) / this.rows.length) * 100;
    },
  },
  data() {
    return {
      destinations: [],
      importing: false,
      dialog: false,
      isGeocoding: false,
      showGeocodeError: false,
      geoCodingValue: 0,
      failedGeocodes: 0,
    };
  },
  methods: {
    ...mapActions('geo', [GEOCODE]),
    pluralize,
    mapValues(field, value) {
      for (let i = 0; i < this.destinations.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        this.destinations[i][field.value] = rowVal;
      }
    },
    async importDestinations() {
      this.importing = true;
      const d = this.destinations.map((e) => ({
        name: e.name,
        category: e.category,
        address: {
          address: e.address,
          city: e.city,
          state: e.state,
          zip: e.zip,
          lat: e.coordinates.split(',')[0],
          lng: e.coordinates.split(',')[1],
        },
        liabilityExp: e.liabilityExp,
        phone: e.phone,
        email: e.email,
        active: true,
      }));
      if (d.length > 0) {
        try {
          this.$refs.importTableRef.cleanText(d);
          await importApi.importDestinations(d);
          this.$myalert.success('Destinations imported successfully');
        } catch (e) {
          this.$myalert.error(`Failed to import destinations: ${e.message}`);
        }
      } else {
        this.$myalert.error('No valid destinations found');
      }
      this.importing = false;
    },
    async geocodeDestinations() {
      this.dialog = true;
      this.geoCodingValue = 0;
      try {
        this.isGeocoding = true;
        let iterator = 0;
        this.failedGeocodes = 0;
        for (const destination of this.destinations) {
          iterator++;

          this.geoCodingValue = iterator;
          const addr = {
            address: destination.address,
            city: destination.city,
            zip: destination.zip,
            state: destination.state,
          };
          try {
            const { lat, lng } = await this[GEOCODE](addr);
            destination.coordinates = `${lat},${lng}`;
          } catch (error) {
            this.failedGeocodes++;
            destination.coordinates = '';
          }
        }
        this.isGeocoding = false;
      } catch (error) {
        this.showGeocodeError = true;
      }
    },
  },
  watch: {
    rows() {
      this.destinations = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.destinations.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
};
</script>

<style scoped></style>
