var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("import-table", {
        ref: "importTableRef",
        attrs: {
          fileHeaders: _vm.fileHeaders,
          rows: _vm.rows,
          fields: _vm.fields,
          items: _vm.destinations,
          importing: _vm.importing,
          showgeocodeButton: "",
        },
        on: {
          mapValues: _vm.mapValues,
          import: _vm.importDestinations,
          geocode: _vm.geocodeDestinations,
        },
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5 grey lighten-2" },
                    [
                      _vm._v(
                        " Geocode " +
                          _vm._s(_vm.rows.length) +
                          " " +
                          _vm._s(_vm.pluralize("Place", _vm.rows.length)) +
                          " "
                      ),
                    ]
                  ),
                  !_vm.showGeocodeError
                    ? _c(
                        "v-card-text",
                        { staticClass: "py-2" },
                        [
                          _vm.isGeocoding
                            ? _c("p", [
                                _vm._v(
                                  "Geocoding Location " +
                                    _vm._s(_vm.geoCodingValue) +
                                    "/" +
                                    _vm._s(_vm.rows.length)
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isGeocoding &&
                          _vm.geoCodingValue == _vm.rows.length
                            ? _c("p", [
                                _vm._v(
                                  " Geocoding complete for " +
                                    _vm._s(
                                      _vm.geoCodingValue - _vm.failedGeocodes
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.pluralize("place", _vm.rows.length)
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _c("v-progress-linear", {
                            attrs: {
                              color: "light-green darken-4",
                              height: "10",
                              value: _vm.progressValue,
                            },
                          }),
                        ],
                        1
                      )
                    : _c("v-card-text", { staticClass: "py-2" }, [
                        _vm._v(" Geocoding could not be completed. "),
                      ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      (!_vm.isGeocoding &&
                        _vm.geoCodingValue == _vm.rows.length) ||
                      _vm.showGeocodeError
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }